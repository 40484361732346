import Vue from 'vue';
import App from './App.vue';
import NProgress from 'vue-nprogress';
import router from './router';
import store from './store';
import "prismjs";
import "prismjs/themes/prism.css";
import 'prismjs/components/prism-json';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import mdiVue from 'mdi-vue/v2';
import * as mdijs from '@mdi/js';


Vue.use(mdiVue, {
  icons: mdijs
}) ;

Vue.use(NProgress, { router: false });

Vue.use(Toast, {
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
});

new Vue({
  router,
  store,
  nprogress: new NProgress(),
  render: h => h(App)
}).$mount('#app')