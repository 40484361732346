import * as amplitude from '@amplitude/analytics-browser';
import router from '../router';

const instance = amplitude.createInstance();

instance.add({
  name: 'page-view-tracking-enrichment',
  type: 'enrichment',
  setup: async () => undefined,
  execute: async (event) => {
    if (event.event_type !== '[Amplitude] Page Viewed') {
      return event;
    }

    event.event_properties = {
      ...event.event_properties,
      'Section Name': router.currentRoute.meta?.insights?.sectionName,
      'Page Name': router.currentRoute.meta?.insights?.pageName,
      'Page ID': router.currentRoute.params.id
    };
    return event;
  },
});

const init = () => {
  instance.init('2b3ac17714df62887ccb48a2c0029368', {
    defaultTracking: {
      attribution: false,
      pageViews: true,
      sessions: false,
      formInteractions: false,
      fileDownloads: false,
    },
  });

  return instance;
}


export { init, instance as amplitude };


