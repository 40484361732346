<template>
  <div id="app">
    <nprogress-container></nprogress-container>
    <div id="navigation" v-if="($store.getters.signedIn)">
      <div @dragend="syncContent" class="logo" @click="goHome()">
        <img :src="`${publicPath}images/logo-black@2x.svg`" alt="" style="height: 100%;"/>
      </div>
      <div class="hamburger sm:hidden cursor-pointer" style="width: 30px;" @click="mobile_menu_displayed = !mobile_menu_displayed;">
        <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100%">
          <path
            d="M 5 8 A 2.0002 2.0002 0 1 0 5 12 L 45 12 A 2.0002 2.0002 0 1 0 45 8 L 5 8 z M 5 23 A 2.0002 2.0002 0 1 0 5 27 L 45 27 A 2.0002 2.0002 0 1 0 45 23 L 5 23 z M 5 38 A 2.0002 2.0002 0 1 0 5 42 L 45 42 A 2.0002 2.0002 0 1 0 45 38 L 5 38 z" />
        </svg>
      </div>
      <div class="navigation-links fixed sm:static" :mobile-shown="mobile_menu_displayed">
        <span :active="$route.path.startsWith('/knowledge-base')" @click="mobile_menu_displayed = false; $router.push('/knowledge-base').catch(_ => {})">Knowledge Base</span>
        <span :active="$route.path.startsWith('/learning-paths')" @click="mobile_menu_displayed = false; $router.push('/learning-paths').catch(_ => {})">Learning Paths</span>
        <span :active="$route.path.startsWith('/releases')" @click="mobile_menu_displayed = false; $router.push('/releases').catch(_ => {})">What's New</span>
        <span @click="logout">Logout</span>
      </div>
    </div>
    <router-view v-if="(content_loaded || !$store.getters.signedIn)" v-slot="{ Component }" :key="routerKey">
      <component :is="Component" />
    </router-view>
  </div>
</template>

<script>
import firebase from '@/firebase';
import NprogressContainer from 'vue-nprogress/src/NprogressContainer';
import { init, amplitude } from '@/amplitude';


export default {
  components: {
    NprogressContainer
  },

  data: () => ({
    publicPath: process.env.BASE_URL,
    mobile_menu_displayed: false,
    routerKey: '',
    content_loaded: false,
  }),

  created() {
    // Init amplitude
    init();
   
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        await this.$store.dispatch('checkUserClaims', true);
        await this.$store.dispatch('getArticlesPreview');
        await this.$store.dispatch('getLearningPaths');
        await this.$store.dispatch('getLearningPathModules');
        await this.$store.dispatch('getAcademySettings');

        this.content_loaded = true;
        amplitude.setUserId(user.uid);

      } else {
        console.log('not logged in');
      }
    });
  },

  methods: {
   logout() {
      this.$store.commit('setLoading', true);
      this.mobile_menu_displayed = false;
      this.$store
        .dispatch('logout')
        .then(result => {
          this.$store.commit('setLoading', false);
          this.$router.push(this.$route.query.redirectUrl || '/auth').catch(() => { });
        }).catch(err => {
          this.$store.commit('setLoading', false);
          this.$toast.error(err.message);
        });
   },

   goHome() {
    this.$router.push('/').catch(_ => {});
    this.routerKey = Math.random();
   },

  //  To SYNC content (only allowed by Schaman employees)
   async syncContent() {
    if (firebase.auth().currentUser && firebase.auth().currentUser.email.split('@')[1].toLowerCase() !== 'schaman.com') {
      return;
    }

    console.log('Started syncing content...');
    let r = await fetch('https://schamanacademysync-4w2do4odbq-no.a.run.app/api/sync');
    
    if (r.status === 200) {
      window.location.reload();
    } else {
      console.log(await r.json());
    }
   }
  }
}
</script>

<style>
@import './assets/styles/default.css';
@import './assets/styles/variables.css';
@import './assets/styles/style.css';
@import './assets/styles/animations.css';
@import './assets/styles/notion.css';


#navigation {
  z-index: 999;
  height: 68px;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 25px 10px 20px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
  border-bottom: 1px solid rgb(229, 229, 229);
  position: sticky;
  top: 0px;
  backdrop-filter: blur(15px);
}

#navigation .logo {
  display: block;
  padding-bottom: 2px;
  cursor: pointer;
}

#navigation .navigation-links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
}

#navigation .navigation-links span {
  display: block;
  cursor: pointer;
  user-select: none;
}

#navigation .navigation-links span[active=true] {
  color: var(--primary-color);
}

#navigation .navigation-links span:hover {
  color: var(--primary-color);
}


/* For mobile  */
@media (max-width: 639px) {
  #navigation .navigation-links:not([mobile-shown=true]) {
    display: none;
  }

  #navigation .navigation-links {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    top: 68px;
    left: 0;
    width: 100%;
    backdrop-filter: blur(15px);
    background-color: rgba(255, 255, 255, 0.8);
    gap: 0;
    border-bottom: 1px solid #ddd;
    z-index: 999;
  }

  #navigation .navigation-links span {
    display: block;
    cursor: pointer;
    user-select: none;
    padding: 12px;
    width: 100%;
    text-align: center;
  }
}
</style>
