import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';
// import 'firebase/functions';
// import 'firebase/messaging';
// import 'firebase/analytics';

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID
};

const f = firebase.initializeApp(config);

// f.messaging.isSupported = firebase.messaging.isSupported();

// if (f.messaging.isSupported) {
//   f.messaging().usePublicVapidKey(process.env.VUE_APP_FIREBASE_VAPIDKEY);
// }

// firebase.analytics();


export default f;
